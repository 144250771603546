import { DistrictRegionData } from "./districtRegionData";

export const getDistricts = (country) => {
  const items = DistrictRegionData[country];
  if (items) return Object.keys(items);
};

export const getRegions = (country, district) => {
  const items = DistrictRegionData[country];
  if (district) return items[district];
};

export const setDefaultDistrict = (value) => {
  switch (value) {
    case "Nigeria":
      return "Lagos And West 1";
    case "Uganda":
      return "West";
    case "Tanzania":
      return "South";
    case "South Africa":
      return "Cen-Vaal";
    case "Mozambique":
      return "South";
    case "Zambia":
      return "North";
    case "Ghana":
      return "North";
    default:
      return "";
  }
};
