export const DistrictRegionData = {
  Ghana: {
    North: [
      "Ashanti",
      "Bono",
      "North West",
      "North East",
      "South West",
      "Western North",
    ],
    South: ["Accra Central", "Accra East", "Accra West", "South East"],
    East: ["...XYZ"],
    West: ["...XYZ"],
  },
  "South Africa": {
    "Cen-Vaal": ["Maluti"],
    East: ["...XYZ"],
    West: ["...XYZ"],
  },

  Tanzania: {
    South: [
      "Chunya",
      "Iringa",
      "Lindi",
      "Makambako",
      "Mbeya",
      "Mbinga",
      "Morogoro",
      "Mtwara",
      "Nanyumbu",
      "Njombe",
      "Nyasa",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Songea Rural",
      "Songea",
      "Songwe",
    ],

    Central: ["Dar es Salaam", "Dodoma", "Morogoro", "Pwani"],

    East: ["Werty", "Nai", "Abia", "Sucotset"],

    "North East": [
      "Arusha",
      "Chome",
      "Kilimanjaro",
      "Kisiwani",
      "Lembeni",
      "Lushoto",
      "Manyara",
      "Moshi",
      "Mwanga",
      "Same",
      "Simanjiro",
      "Singida",
      "Tanga",
    ],

    "North West": [
      "Bukoba",
      "Bwanga",
      "Chato",
      "Chato, Old stand",
      "Chato,Muganza",
      "Geita",
      "Kagera",
      "Kahama",
      "Kamachumu",
      "Kasulu Town",
      "Katavi",
      "Kigoma",
      "Mara",
      "Maragarasi",
      "Meatu",
      "Mganza Kasenda",
      "Mganza",
      "Mpanda",
      "mpandq",
      "Muleba Nshamba",
      "Muleba",
      "Mwanza",
      "Nguruka",
      "nindo",
      "Nzega",
      "Serengeti",
      "Shinyanga",
      "Simiyu",
      "Tabora",
      "Tarime",
      "Usinge",
      "Uvinza",
    ],
  },
  Mozambique: {
    South: ["Maputo"],

    East: ["...XYZ"],
  },
  Uganda: {
    West: [
      "Amowa",
      "Arua",
      "Biharwe",
      "Bugabo",
      "Bunagana",
      "Bwizibwera",
      "Cyanika",
      "Dwaniro",
      "Igayaza",
      "Kagadi",
      "Kageti",
      "Kamukuzi",
      "Kansanga",
      "Kashari",
      "Katikala",
      "Ki",
      "Kibaale District",
      "Kibaale",
      "Kiboga",
      "Kindeke",
      "Kinoni",
      "Kiruhura",
      "Kisoro",
      "Kiyanja",
      "Koranolya",
      "Kyankwanzi",
      "Masaka",
      "Mbale",
      "Mbarara",
      "Mile 16",
      "Mirama",
      "Ntwentwe",
      "Nyamitanga",
      "Nyeihanga",
      "Rubindi",
      "Rushere",
      "Ruti",
      "Rutooma",
      "Rutti",
      "Rwampara",
      "Rwentojo",
      "Sabga",
      "Sanga",
      "Terego",
      "Tetego",
    ],
    East: [
      "Bubuule",
      "Budondo",
      "Buikwe",
      "Bukoto",
      "Bulo",
      "Bulwada",
      "Buwama",
      "Buwate",
      "Central",
      "East",
      "Entebbe",
      "Jinja katosi rd",
      "Jinja",
      "Jonja",
      "Kabasanda",
      "Kabulasoke",
      "Kalagala",
      "Kamapal",
      "Kamengo",
      "Kampala buddo",
      "Kampala",
      "Kanoni",
      "Kansanga",
      "Katakwi",
      "Kayabwe",
      "Kayunga rd",
      "Kayunga",
      "Kifampa",
      "Kira Kiyenda",
      "Kira",
      "Kiriri",
      "Kitunzi",
      "Kiwatule",
      "Kiyindi",
      "Kla",
      "Kubbiri",
      "Kungu",
      "Kyabadaza",
      "Kyengera",
      "Kyotera",
      "Lungujja",
      "Makerere",
      "Masaka",
      "Mengo",
      "Mpambile",
      "Mpigi",
      "Nabingo",
      "Najja",
      "Nakasongola Town",
      "Nakasozi",
      "Nakawuka",
      "Namawojja",
      "Namunga",
      "Namuwongo",
      "Nateete",
      "Nazigo",
      "Nsambwe",
      "Ntinda",
      "Nyenga",
      "Pallisa",
      "Rukugugiri",
      "Rural 1",
      "Rutete",
      "Senge",
      "Senyi",
      "Serere",
      "Soroti",
      "Taba",
      "Toroma",
      "Urban",
      "Wakaliga",
      "Wakio",
      "Wakiso",
      "Waskio",
      "Wisko",
    ],
    South: ["Lusaka"],
    "South West": ["Kinoni", "Nyamitanga"],
  },
  Zambia: {
    North: ["Chililabombwe", "Chingola", "Kitwe", "Ndola"],
    South: ["Lusaka"],
  },

  Nigeria: {
    "Lagos And West 1": ["Lagos North", "Lagos South", "West 1"],
    "North And West 2": ["Abuja", "Benin", "Ilesa"],
    "South East": ["Aba", "Onitsha", "Port Harcourt"],
  },
};
