import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowRightImg from "../../assets/images/arrowRight.png";
import ArrowDownImg from "../../assets/svg/arrowDown.svg";
import BellImg from "../../assets/svg/bell.svg";
import DisThumbImg from "../../assets/svg/disThumb.svg";
import InsightsImg from "../../assets/svg/insights.svg";
import WarehouseImg from "../../assets/svg/warehouse.svg";
import NavbarRightSection from "../../components/common/NavbarRightSection";
import KJGoBack from "./KJGoBack";
import KJHamburger from "./KJHamburger";

const DefaultNav = ({
  back,
  code,
  distributor,
  pageTitle,
  total,
  toggleNavigation,
  result,
}) => {
  const { t } = useTranslation();
  const allDistributors = useSelector(
    (state) => state.AllDistributorReducer.all_distributors
  );
  const lowStockProducts = useSelector(
    (state) => state.InventoryReducer.low_stock_values
  );

  const [{ distList, loadingState }, setIsLoading] = useState({
    disList: false,
    loadingState: false,
  });

  const generateData = useMemo(
    (code) => {
      const gety = allDistributors?.filter((dist) => {
        return dist?.DIST_Code === code;
      })[0];
      return {
        name: gety ? gety.company_name : "",
        salesForceCode: gety ? gety.SF_Code : "",
      };
    },
    [allDistributors]
  );

  const updateIsLoading = (name) => {
    setIsLoading((prev) => ({ ...prev, [name]: !prev.name }));
  };

  return (
    <div className="w-full h-16 border-b-[1px] border-gray-21 flex items-center">
      <div className="flex items-center w-full px-4 lg:pl-10 lg:pr-8 lg:justify-between">
        <KJHamburger toggleNavigation={toggleNavigation} />
        <div className="flex items-center justify-between w-full">
          <div className="flex w-3/5 mt-1 md:w-2/5">
            <div className="flex items-center">
              {back && <KJGoBack />}
              <h2 className="text-xl font-bold text-black capitalize font-customRoboto">
                {t(pageTitle)}{" "}
                {total && (
                  <span className="font-customGilroy text-grey-text ml-0.5 font-normal">
                    ({total})
                  </span>
                )}
              </h2>
            </div>
          </div>

          <div className="flex items-center justify-between md:w-1/2 lg:w-3/5">
            <div className="relative hidden lg:block md:mr-3 md:w-1/2 lg:w-1/2 16inch:w-2/5">
              <div
                className="items-center hidden cursor-pointer lg:flex"
                id="menu2-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                {distributor?.company_name && (
                  <div className="flex items-center gap-x-2">
                    <img className="h-[65%]" alt="" src={WarehouseImg} />
                    <div>{distributor?.company_name}</div>
                  </div>
                )}
                {result?.length > 1 && (
                  <div
                    onClick={() => updateIsLoading("distList")}
                    className="ml-2"
                  >
                    <img alt="d-icon" src={ArrowDownImg} />
                  </div>
                )}
              </div>

              <div className="h-[20%]">
                {!loadingState && distList && (
                  <div className="origin-top-right absolute r-[16%] mt-[2.8%] w-auto min-w-[320px] z-[10000] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {result.map((code, index) => (
                      <div
                        className={`${
                          distList ? "flex" : "hidden"
                        } px-4 py-5 h-auto w-full hover:cursor-pointer`}
                        onClick={() =>
                          (window.location.href = `/dashboard/overview/${code}`)
                        }
                        key={index}
                      >
                        <div key={index} className="flex w-full">
                          <img className="mr-6" alt="" src={DisThumbImg} />
                          <div className="text-left">
                            <p className="text-base font-semibold">
                              {generateData(code)?.name}
                            </p>
                            <p className="mt-1 text-sm font-normal">
                              {generateData(code)?.SYS_Code}
                            </p>
                          </div>
                        </div>
                        <div className="mr-3 my-auto w-[0.525rem] h-[0.9rem]">
                          <img alt="arr_right" src={ArrowRightImg} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="relative flex items-center justify-between mt-1">
              <Link
                to={`/dashboard/insights/${code}`}
                className="hidden sm:flex"
              >
                <div className="flex mr-8 justify-evenly items-center bg-black text-white px-4 py-2 rounded-3xl overflow-hidden w-[8rem]">
                  <img
                    className="flex-none mr-2 h-4/5"
                    alt="insights_img"
                    src={InsightsImg}
                  />
                  <p
                    className={`${
                      lowStockProducts.length > 0 ? "mr-3 flex-1" : null
                    }`}
                  >
                    {t("Insights")}
                  </p>
                  {lowStockProducts.length > 0 && (
                    <div className="flex-none w-3 h-3 bg-red-500 rounded-full"></div>
                  )}
                </div>
              </Link>
            </div>

            <div className="relative flex items-center justify-between mt-1">
              <Link
                to={`/dashboard/notifications/${code}`}
                className="hidden sm:flex"
              >
                <div className="relative flex items-center justify-between mr-8">
                  <img className="mr-1 h-4/5" alt="" src={BellImg} />
                  <p>{t("notifications")}</p>
                </div>
              </Link>
              <NavbarRightSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultNav;
